<template>
  <div class="debtor-list">
    <h2 class="editable-item__header">
      {{ pageTitle }}
    </h2>
    <sm-breadcrumbs
      class="debtor-list__breadcrumbs"
      :items="breadcrumbs"
    />

    <div class="debtor-list__button-wrapper">
      <sm-button
        class="debtor-list__button"
        :isLoading="isLoadingDebtorsButton"
        @click="handleDebtors"
      >
        Загрузить список
      </sm-button>
      <sm-button
        class="debtor-list__button"
        :isLoading="isLoadingDebtorsButton"
        @click="openConfirmModal"
      >
        Оповестить должников по СМС
      </sm-button>
    </div>

    <sm-datatable
      v-if="isListLoaded"
      class="debtor-list__datatable"
      :headers="headers"
      :items="formattedItems"
      select-items-per-page
      pagination
      :total-items="items.length"
      :items-per-page="count"
      :activePage="page"
      @change-items-per-page="onItemsPerPage"
      @change-page="onChangePage"
    >
      <template v-slot:url="{ row }">
        <a
          target='_blank'
          :href="row.url"
        >
          {{ row.url }}
        </a>
      </template>
    </sm-datatable>

    <div
      v-else
      class="debtor-list__empty"
    >
      <p class="debtor-list__message">
        Список не загружен.
      </p>
    </div>

    <sm-modal    
      :show="isConfirmModal"
      modalTitle="Внимание!"        
      @close="closeConfirmModal"
    >
      <template #body>
        <div class="debtor-list__modal-content">
          <p class="debtor-list__message">
            Подтвердите запуск оповещения должников.
          </p>
          <div class="debtor-list__modal-footer">
            <sm-button
              class="debtor-list__button"
              @click="handleConfirm"
            >
              Подтвердить
            </sm-button>
            <sm-button
              class="debtor-list__button"
              @click="closeConfirmModal"
            >
              Отменить
            </sm-button>
          </div>
        </div>
      </template>
    </sm-modal>
    <div
      v-if="isError"
      class="notifications__container debtors-list__error-container"
    >
      <sm-notification-message
        header="Ошибка"
        type="error"
        :text="error"
        @hide="handleCloseError"
      />        
    </div>
  </div>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// components
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmModal from '@/components/common/modals/SmModal.vue';
import SmNotificationMessage from '@/components/common/notification/SmNotificationMessage.vue';

export default {
  name: 'DebtorList',

  components: {
    SmBreadcrumbs,
    SmDatatable,
    SmButton,
    SmModal,
    SmNotificationMessage,
  },

  data() {
    return {
      pageTitle: 'Список должников',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Список должников',
        },
      ],
      headers: [
        { text: 'ID', alias: 'customerID', order: 'customerID' },
        { text: 'Наименование', alias: 'customerName', order: 'customerName' },
        { text: 'Задолженность', alias: 'debt', order: 'debt' },
        { text: 'Ошибка', alias: 'error', order: 'error' },
        { text: 'Телефон', alias: 'phone', order: 'phone' },
        { text: 'Ссылка для клиента', alias: 'url', order: 'url' },
      ],
      items: [],
      count: 10,
      skip: 0,
      isError: false,
      error: '',
      isListLoaded: false,
      isLoadingDebtorsButton: false,
      isConfirmModal: false,
    };
  },

  computed: {
    page() {
      return this.skip / this.count + 1;
    },

    formattedItems() {
      const start = this.skip;
      const end = this.skip + this.count;
      return this.items.map(item => {
        return {
          ...item,
          debt: new Intl.NumberFormat("ru").format(item.debt).replace(',', '.') + ` ₽`,
        }
      }).slice(start, end);
    },    
  },

  methods: {
    ...mapActions({
      notificationAboutDebt: 'debtors/notificationAboutDebt',
    }),

    openConfirmModal() {
      this.isConfirmModal = true;
    },

    closeConfirmModal() {
      this.isConfirmModal = false;
    },

    handleConfirm() {
      this.handleDebtors(true);
      this.closeConfirmModal();
    },

    async handleDebtors(isNeedSendSms = false) {
      this.isLoadingDebtorsButton = true;
      try {
        const {data, error, isSucceed} = await this.notificationAboutDebt(isNeedSendSms);
        if (isSucceed && data.length) {
          this.onChangePage(1);
          this.items = data;
        } else {          
          this.error = error || "Нет данных по должникам";
          this.isError = true;
        }        
      } catch (error) {
        this.error = error;
        this.isError = true;
      } finally {
        this.isListLoaded = true;
        this.isLoadingDebtorsButton = false;
      }
    },  

    handleCloseError() {
      this.isError = false;
      this.error = '';
    },

    onItemsPerPage(count) {
      this.skip = 0;
      this.count = count;
    },

    onChangePage(page) {
      console.log('onChangePage', page)
      this.skip = (page - 1) * this.count;
      console.log('skip', this.skip)
    },
  },
};
</script>

<style lang="scss">

.editable-item__header {
  margin-bottom: 30px;
}

.debtor-list__breadcrumbs {
  margin-bottom: 15px;
}

.debtor-list__button-wrapper {
  display: flex;
  justify-content: end;
  padding: 0 15px;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;
}

.debtor-list__button {
  width: 180px;
}

.debtor-list__empty {
  margin-top: 27px;
  padding: 40px 0;
  border: 1px solid var(--blue);
}

.debtor-list__message {
  text-align: center;
}

.debtor-list__modal-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}
</style>
